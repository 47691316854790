import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { RulesprocessorConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AlertRuleService } from './api/alert-rule.service';
import { AlertRuleTemplateService } from './api/alert-rule-template.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class RulesprocessorApiModule {
    public static forRoot(configurationFactory: () => RulesprocessorConfiguration): ModuleWithProviders<RulesprocessorApiModule> {
        return {
            ngModule: RulesprocessorApiModule,
            providers: [ { provide: RulesprocessorConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: RulesprocessorApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('RulesprocessorApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
