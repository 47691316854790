/**
 * Condition Monitoring APIs
 * APIs for Condition Monitoring Web Project.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RecipientType = 'Mobile' | 'Mail';

export const RecipientType = {
    MOBILE: 'Mobile' as RecipientType,
    MAIL: 'Mail' as RecipientType
};

