import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { ManagementConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessGroupService } from './api/access-group.service';
import { ApplicationService } from './api/application.service';
import { ApplicationGroupService } from './api/application-group.service';
import { BusinessUnitService } from './api/business-unit.service';
import { CustomEntityService } from './api/custom-entity.service';
import { DeviceService } from './api/device.service';
import { DeviceAccessGroupService } from './api/device-access-group.service';
import { DeviceMetadataService } from './api/device-metadata.service';
import { DisplayUserService } from './api/display-user.service';
import { HierarchyService } from './api/hierarchy.service';
import { ManagedIdentityService } from './api/managed-identity.service';
import { PermissionService } from './api/permission.service';
import { ServicePrincipalService } from './api/service-principal.service';
import { UserService } from './api/user.service';
import { UserGroupService } from './api/user-group.service';
import { UserOnBoardingService } from './api/user-on-boarding.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ManagementApiModule {
    public static forRoot(configurationFactory: () => ManagementConfiguration): ModuleWithProviders<ManagementApiModule> {
        return {
            ngModule: ManagementApiModule,
            providers: [ { provide: ManagementConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ManagementApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ManagementApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
